import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"

const Maintenances = ({ location }) => {
  return (
    <Layout lang="en-US">
      <Seo
        pagetitle="Outage and Maintenance Information"
        pagepath={location.pathname}
        pagedesc="Outage and Maintenance Information | PARKLoT offers a Twitter campaign tool that can be run as many times as you want for a monthly fee."
      />
      <Breadcrumb title="Outage and Maintenance Information" />
      <main className="information">
        <section className="information__section l-container--m">
          <h1 className="information__title">Current Outage Information</h1>
          <ul className="maintenance__list">
            <li className="maintenance__item">
              <p className="maintenance__title">Notice</p>
              <time className="maintenance__time">2021/2/24</time>
              <p className="maintenance__text">
                <a className="information__link" href="/">
                  Notice of Discontinuation of New Campaign Creation Function
                </a>
              </p>
            </li>
            <li className="maintenance__item">
              <p className="maintenance__title">
                Disability
                <span className="maintenance__badge badge--secondary">
                  Restoration
                </span>
              </p>
              <time className="maintenance__time">2020/12/19</time>
              <p className="maintenance__text">
                <a className="information__link" href="/">
                  Notice of Failure Caused by Twitter API
                </a>
              </p>
            </li>
          </ul>
        </section>
      </main>
    </Layout>
  )
}

export default Maintenances
